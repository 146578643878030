// =====================================
// Breadcrumbs
// =====================================

.breadcrumbs {
    display: none;
    padding-top: em(30);
    padding-bottom: em(30);
    font-weight: 600;
    background-color: #fff;
    text-transform: uppercase;
}

.breadcrumbs-inner {
    @include site-outer;
}

.breadcrumbs-label {
    display: inline-block;
    margin-right: 2em;
    font-size: em(14);
}

.breadcrumbs-list {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.breadcrumbs-crumb {
    display: inline-block;
    color: $colour-primary;
    font-size: em(14);
}

.breadcrumbs-crumb:hover {
    color: inherit;
}

.breadcrumbs-crumb:not(:last-child):after {
    display: inline-block;
    height: 10px;
    width: 7px;
    margin-left: 1em;
    margin-right: 1em;
    content: '>';
    color: $colour-primary;
}

.breadcrumbs-crumb:last-of-type {
    color: inherit;
}

@include media(medium) {
    .breadcrumbs {
        display: block;
    }
}
