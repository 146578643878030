// =====================================
// Hero
// =====================================

.hero {
    margin-bottom: em(50);
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
    background-color: $colour-slate;
}

.hero-item {
    width: 100%;
}

.hero-image {
    width: 100%;
}

.hero-title {
    margin-bottom: 1em;
}

// We later use this to help postion the content on larger screens
.hero-content-container {
    padding-top: em(30);
    padding-bottom: em(30);
    color: #fff;
}

.hero-content {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
}

.hero-image-container {
    position: relative;
}

.hero-info {
    position: absolute;
    top: 15px;
    right: 20px;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    font-weight: bold;
    background-color: #fff;
    color: $colour-slate;
    transition: all 200ms linear;
}

.hero-info:focus {
    background-color: $colour-slate;
    color: #fff;
    outline: 0;
}

.hero-tooltip {
    position: absolute;
    top: 45px;
    right: 20px; 
    margin: 0 auto em(10) auto;
    max-width: em(300);
    padding: em(5) em(10);

    visibility: hidden;
    font-size: em(14);
    background-color: $colour-slate;
    color: #fff;
    transition: opacity 200ms linear;
    opacity: 0;
    
    p {
        margin-bottom: 0;
    }
}

.hero-info:focus + .hero-tooltip {
    opacity: 1;
    visibility: visible;
}

.hero-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colour-tertiary;
    font-size: em(14);
    font-weight: 600;
    text-transform: uppercase;
}

.hero-cta:hover {
    color: #fff;
}

.hero-cta-icon {
    margin-left: 20px;
}

.hero {

    .flickity-prev-next-button {
        display: none;
    }

    .flickity-page-dots {
        position: static;
        padding-bottom: em(30);
        z-index: 1;
    }

    .flickity-page-dots .dot {
        z-index: 5;
        background-color: transparent;
        border: 1px solid #fff;
        opacity: 1;
    }

    .flickity-page-dots .dot.is-selected {
        background-color: #fff;;
    }

}

@include media(medium) {

    .hero-title {
        margin-bottom: 0.5em;
    }

}

@include media(large) {

    .hero {
        margin-bottom: em(100);

        .flickity-prev-next-button {
            display: inline-block;
            background-color: transparent;
        }

        .flickity-prev-next-button .arrow {
            fill: #fff;
        }

        .flickity-page-dots {
            display: none;
        }
    }

}
