// =====================================
// Layout
// =====================================

@mixin site-outer {
    margin-left: auto;
    margin-right: auto;
    max-width: em($site-outer-width);
    width: 100% - (2 * $site-gutter);
}

@mixin site-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: em($site-inner-width);
    width: 100% - (2 * $site-gutter);
}

.l-site-outer {
    @include site-outer;
}

.l-site-inner {
    @include site-inner;
}
