// =====================================
// Listing
// =====================================

.listing {
    margin-bottom: em(40);
    padding-bottom: em(40);
    border-bottom: 1px solid $colour-grey;
}

.listing-meta {
    margin-bottom: em(14);
    font-size: em(18);
}

.listing-image {
    margin-bottom: em(25);
    width: 100%;
}

.listing-categories {
    @include clearfix;
    margin-bottom: em(16);
}

.listing-category {
    @include link;
}

.listing-category:hover {
    color: $colour-grey;
}

.listing-category:not(:last-child):after {
    content: '|';
    display: inline-block;
    margin-left: em(16);
    margin-right: em(16);
    color: $colour-slate;
}

@include media(medium) {
    .listing {
        margin-bottom: em(70);
        padding-bottom: em(70);
    }

    .listing-meta {
        margin-right: em(50);
        margin-bottom: 0;
        width: 10em;
        float: left;
    }

    .listing-main {
        overflow: hidden;
    }

    .listing-image {
        margin-bottom: em(40);
    }

    .listing-categories {
        margin-bottom: em(30);
    }

    .listing-description {
        margin-bottom: em(30);
    }

    .listing-title {
        margin-bottom: em(15)
    }
}
