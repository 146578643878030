// =====================================
// Accordion
// =====================================

/*
<div class="accordion-group">
    <h2 class="accordion-group-title heading-three">{category_name}</h2>
    <div class="accordion accordion--bare js-accordion">
        <button class="accordion-header js-accordion-control">
            <h4 class="accordion-title">{question}<svg class="accordion-icon"><use xlink:href="/dist/icons/sprite.svg#icon-chevron"></use></svg></h4>
        </button>
        <div class="accordion-body js-accordion-panel">
            <p>{answer}</p>
        </div>
    </div>
</div>
 */

.accordion {
    text-align: left;
}

.accordion-icon {
    display: none;
    width: 19px;
    height: 11px;
    fill: $colour-primary;
    margin-left: auto;
}

.accordion-group {
    margin-bottom: em(50);
}

.accordion-group-title {
    margin-bottom: 0.6em;
}

.accordion-title {
    @extend .heading-four;
    text-align: left;
    text-transform: uppercase;
}

.accordion-header {
    width: 100%;
}

.accordion-title-group {
    margin-bottom: em(50);
}

.accordion-body-media {
    margin-bottom: em(30);
}

.js {

    .accordion-title {
        display: flex;
        align-items: center;
        margin: 0;
    }

    .accordion-body {
        display: none;
    }

    .accordion-body > :last-child {
        margin-bottom: 0;
    }

    .accordion-icon {
        display: block;
    }

    .accordion-header {
        padding: 0.6em 0;
    }

    .accordion-body {
        padding: 1.1em 0;
    }

    // Active styles
    .accordion.is-active {

        .accordion-icon {
            fill: $colour-grey;
            transform: rotate(180deg);
        }

        .accordion-body {
            display: block;
        }

    }

    .accordion {
        border-top: 1px solid $colour-grey;
    }

    .accordion:last-child {
        border-bottom: 1px solid $colour-grey;
    }

    .accordion--filled {
        background-color: #fff;
        border-top: 0;
        margin-bottom: 1em;
    }

    .accordion--filled:last-child {
        border-bottom: 0;
    }

    .accordion--filled .accordion-body,
    .accordion--filled .accordion-header {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .accordion-header:focus {
        outline: 0;
    }

    @include media(medium) {
        .accordion-header {
            padding-top: 1.2em;
            padding-bottom: 1.2em;
        }
    }

}

@include media(medium) {
    .accordion-group {
        margin-bottom: em(100)
    }
}
