// =====================================
// Article Footer
// =====================================

.article-footer {
    padding-top: em(50);
    padding-bottom: em(50);
    background-color: $colour-secondary;
    text-align: center;
}

// Used to contain and postion the back-link
.article-footer-outer {
    @include site-outer;
    position: relative;
}

.article-footer-inner {
    @include site-inner;
}

.article-footer-posted-by {
    display: block;
    margin-bottom: 0.9em;
    font-size: em(18);
}

.article-footer-meta {
    @extend .heading-two;
    display: block;
    margin-bottom: 0;
}

.article-footer-meta--alt {
    font-weight: 400;
}

.article-footer-back {
    margin-bottom: em(20);
    display: block;
}

@include media(medium) {
    .article-footer {
        padding-top: em(60);
        padding-bottom: em(60);
        text-align: left;
    }

    .article-footer-posted-by {
        margin-bottom: 1.5em;
        font-size: em(21);
    }

    .article-footer-back {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
    }
}
