// =====================================
// Typography
// =====================================

.alert {
    font-weight: 600;
    padding-top: 0.8em;
    padding-bottom: 0.8em;

    a {
        border-bottom: 1px solid #fff;
    }
}

.alert.is-hidden {
    display: none;
}

.alert-container {
    @include site-outer;
}

.alert--info {
    background-color: $colour-primary;
    color: #fff;
}

.alert-message {
    display: inline-block;
    margin-right: 2em;
    font-size: em(16);
    max-width: calc(100% - (2em + 16px));
}

.alert-dismiss {
    display: inline-block;
    margin-left: auto;
    float: right;
}

@include media(medium) {
    .alert {
        padding-top: 1.4em;
        padding-bottom: 1.4em;
    }

    .alert-message {
        font-size: em(18);
    }
}
