// =====================================
// Media Queries
// =====================================

@mixin media($query) {

	$breakpoint-exists: false;

	@each $breakpoint in $media-queries {
		$alias: 	nth($breakpoint, 1);
		$condition: nth($breakpoint, 2);
		@if $query == $alias and $condition {
			$breakpoint-exists: true;
			@media #{$condition} {
				@content;
			}
		}
	}

	@if ($breakpoint-exists == false) {
		@warn "Breakpoint '#{$query}' does not exist";
	}

}
