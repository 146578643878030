// =====================================
// Base
// =====================================

html,
body {
    min-height: 100%;
}

/**
 * Set a more 'natural' global box-sizing
 */

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

/**
 * 1. Force vertical scrollbar to prevent page jump when navigating pages
 *    smaller than the browser viewport.
 */

body {
    background-color: $body-background-colour;
    overflow-y: scroll; // [1]
}

/**
 * Responsive media elements
 */

img,
video {
    max-width: 100%;
    vertical-align: middle;
}

textarea {
    resize: vertical;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

figure {
    margin: 0;
}

figcaption {
	margin: 0.8em 0;
}

address {
    font-style: normal;
}

button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
}

svg {
    fill: currentColor;
    vertical-align: middle;
}
