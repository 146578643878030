.side-nav {
    padding: em(30);

    background-color: #fff;
    width: 250px;
    float: right;

    ul {
        z-index: 600;
        background-color: #fff;
    }
}

.side-nav-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: em(21);
    margin-bottom: 1em;
    display: block;
}

.side-nav-title:after {
    width: 30px;
    height: 3px;
    background-color: $colour-slate;
    display: block;
    content: '';
    margin-top: 1em;
}

.side-nav-item:not(:last-child) {
    margin-bottom: 0.8em;
}

.side-nav-link {
    display: block;
    color: $colour-grey;
    font-weight: 600;
    text-transform: uppercase;
}

.side-nav-link:hover,
.side-nav-link.is-active {
    color: $colour-primary;
}

.sticky-nav {
    z-index: z-index(sticky-nav);
}

.sticky-nav.is-hidden {
    display: none;
}

.sticky-nav.is-fixed-top {
	position: fixed;
}

.sticky-nav.is-fixed-bottom {
	position: absolute;
	bottom: 0;
    right: 0;
}
