/**
 * 	Example usage
 *
 * 	<div class="select-container">
 *  	<select class="select">
 *  		<option>Option One</option>
 *  		<option>Option Two</option>
 *  		<option>Option Three</option>
 *  	</select>
 * 	</div>
 */

.select-container {
    border: $form-input-border;
    background: url('/dist/images/select-arrow.png') no-repeat right 0.35em center;

    @include media(hidpi) {
        background-image: url('/dist/images/select-arrow@2x.png');
    }
}

.select {
    padding: $form-input-padding;
    width: 100%;
    appearance: none;
    border: 0;
    border-radius: 0;
    background-color: transparent;
}

.select:focus {
	outline: 3px solid $colour-primary;
}
