// =====================================
// Footer
// =====================================

.footer {
    padding-top: em(55);
	padding-bottom: em(30);
    background-color: $colour-slate;
    color: #fff;
}

.footer a:hover {
    color: $colour-tertiary;
}

.footer-logo {
    margin-bottom: em(30);
    display: inline-block;
}

.footer ::-webkit-input-placeholder {
    color: #fff;
}

.footer :-moz-placeholder {
    color: #fff;
}

.footer ::-moz-placeholder {
    color: #fff;
}

.footer :-ms-input-placeholder {
    color:#fff;
}

.footer ::-ms-input-placeholder {
    color:#fff;
}

.footer :placeholder-shown {
    color: #fff;
}

.footer-icon {
    margin-right: 30px;
}

.footer-contact {
	margin-bottom: em(25);
}

// -------------------------------------
// Address
// -------------------------------------

.footer-address {
    font-style: normal;
}

.footer-address {
    margin-bottom: em(25);
}


.footer-main-group-left {
    margin-bottom: 2.3em;
}

// -------------------------------------
// Title
// -------------------------------------

.footer-title {
    display: block;
    margin-bottom: 0.75em;
	font-size: em(14);
    font-weight: 600;
    text-transform: uppercase;
}

// -------------------------------------
// Newsletter
// -------------------------------------

.footer-newsletter-input {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid #fff;
    width: 100%;
    margin-bottom: em(30);
    padding: 0.8em 0;
    transition: all 50ms;
}

.footer-newsletter-input:focus {
    outline: 0;
    border-bottom-color: $colour-tertiary;
}

// -------------------------------------
// Credit
// -------------------------------------

.footer-meta {
	margin-top: em(50, 14);
}

.footer-credit {
	display: block;
	font-size: em(14)
}

.footer-credit:not(:last-child) {
	margin-bottom: 0.75em;
}

.footer-credit-alt {
	font-weight: bold;
    text-transform: uppercase;
}

// -------------------------------------
// Navigation
// -------------------------------------

.footer-navigation-group {
    margin-bottom: 1.5em;
}

// -------------------------------------
// Social
// -------------------------------------

.footer-social {
    margin-bottom: em(50);
}

// -------------------------------------
// Links
// -------------------------------------

.footer-links {
	margin-bottom: em(30);
    padding-top: em(30);
    padding-bottom: em(30);
    border-top: 1px solid $colour-grey;
	border-bottom: 1px solid $colour-grey;
}

// -------------------------------------
// List
// -------------------------------------

.footer-list {
	font-size: em(14);
}

.footer-list-link-group {
    display: flex;
    align-items: center;
}

.footer-list-icon {
    display: inline-block;
    width: 20px;
    margin-right: 30px;
    margin-bottom: 0.5em;
}

.footer-worldwide,
.footer-newsletter {
    margin-bottom: 3em;
}

// -------------------------------------
// Affiliates
// -------------------------------------

.footer-affiliates {
	@include clearfix;
    display: flex;
    align-items: center;
}

.footer-affiliate {
	display: block;
	margin-right: 2em;
	float: left;
}

.footer-iip {
	max-width: 130px;
}

.footer-leea {
    max-width: 53px;
}

.footer-address-body {
	font-size: em(14);
}

// -------------------------------------
// Breakpoint - Medium
// -------------------------------------

@include media(footer-medium) {

    .footer {
        padding-top: em(100);
    }

    .footer-navigation,
    .footer-main-a,
    .footer-main-b {
        @include clearfix;
    }

    .footer-logo {
        width: 100%;
    }

	.footer-contact,
	.footer-address {
		margin-bottom: 0;
	}

    .footer-contact,
    .footer-worldwide,
    .footer-navigation-group:nth-of-type(odd)  {
        float: left;
        width: 45%;
        margin-right: 10%;
    }

    .footer-social,
    .footer-address,
    .footer-newsletter,
    .footer-navigation-group {
        float: left;
        width: 45%;
    }

    .footer-links {
        @include clearfix;
    }

    .footer-links li {
        display: inline-block;
        float: left;
        margin-right: 2em;
    }

    .footer-link.footer-link {
        margin-bottom: 0;
    }

	.footer-meta {
		@include clearfix;
	}

	.footer-links-a {
		@include clearfix;
	}

    .footer-credit {
        float: left;

		&:not(:last-child) {
			margin-bottom: 0;
			margin-right: 2em;
		}
    }

}

// -------------------------------------
// Breakpoint - Large
// -------------------------------------

@media (min-width: 70em) {

    $left-width: em(300);
    $right-width: em(900);

    .footer-title {
        margin-bottom: em(25);
    }

    // main
    // -------------------------------------

    .footer-main {
        @include clearfix;
        display: flex;
        margin-bottom: em(50);
        overflow: hidden;
    }

    .footer-main-a {
        display: inline-flex;
        flex-wrap: wrap;
        float: left;
        margin-bottom: 0;
        width: $left-width;
        flex-direction: column;
    }

    .footer-main-b {
        display: inline-flex;
        flex-wrap: wrap;
        margin-left: auto;
        float: right;
        width: $right-width;
    }

    // Logo
    // -------------------------------------

    .footer-logo {
        width: 280px;
    }

    // Address
    // -------------------------------------

    .footer-address {
        float: none;
        width: auto;
        margin: auto 0 0 0;

		.no-flexbox & {
			margin-top: 2em;
		}
    }

    .footer-navigation {
        margin-bottom: em(100);
    }

    // Contact
    // -------------------------------------

    .footer-contact {
        float: none;
        margin-bottom: -0.5em;
        margin-right: 0;
        width: auto;
    }

    // Newsletter & Social
    // -------------------------------------

    .footer-social,
    .footer-newsletter {
        margin-bottom: 0;
    }

    .footer-newsletter {
        float: left;
        width: 50%;
        margin-right: 25%;
    }

    .footer-newsletter-group {
        display: table;
        width: auto;
    }

    .footer-newsletter-input {
        display: table-cell;
        margin-bottom: 0;
    }

    .footer-newsletter-addon {
        display: table-cell;
        padding-left: 2em;
        white-space: nowrap;
        width: 1%;
        vertical-align: bottom;
    }

    .footer-social {
        float: right;
        margin-bottom: -0.5em;
    }

    // Worldwide & Navigation
    // -------------------------------------

    .footer-worldwide {
        margin-right: 0;
        float: right;
    }

    .footer-worldwide {
        width: auto;
    }

    .footer-navigation {
        width: 70%;
		float: left;
		margin-right: 5%;
    }

    .footer-navigation-group.footer-navigation-group {
        width: auto;
        margin-right: 5%;
    }

    .footer-worldwide,
    .footer-social {
		float: left;
        width: 25%;
    }

	.footer-links-a {
		float: left;
	}

	.footer-meta {
		float: right;
		margin-top: 0;
	}

}
