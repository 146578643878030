@mixin link {
	text-transform: uppercase;
	font-size: em(14);
	color: $colour-primary;
	font-weight: 600;
	display: inline-flex;
	align-items: center;

	&:hover {
		color: $colour-grey;
	}

	&-icon {
		margin-left: 0.5em;
	}
}

.cta-link {
	@include link;
}
