// =====================================
// Back to the top
// =====================================

.back-to-top {
    display: inline-flex;
    align-items: center;
    font-size: em(12);
    color: $colour-primary;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        color: $colour-grey;
    }
}

.back-to-top-icon {
    margin-left: 1em;
}

@include media(medium) {
    .back-to-top {
        font-size: em(14);
    }
}
