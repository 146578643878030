// =====================================
// World Map
// =====================================

.world-map {
    display: none;
}

.world-map-container {
	max-width: em(1030);
    position: relative;
}

.world-map-marker {
	content: '';
    position: absolute;
    display: block;
	width: em(14);
	height: em(14);
    border-radius: 50%;
	background-color: $colour-slate;
}

.world-map-title {
	position: absolute;
	top: 20%;
	right: 0;
	max-width: 6em;
}

#map-marker-a {
	top: 44.5%;
	left: 19.5%;
}

#map-marker-b {
	top: 31.5%;
	left: 45.5%;
}

#map-marker-c {
	top: 38%;
	left: 46%;
}

#map-marker-d {
	top: 49%;
	left: 61%;
}

#map-marker-e {
	top: 68%;
    left: 78%;
}

@media (min-width: 60em) {

	.world-map {
        display: block;
        margin-bottom: em(100);
    }

}
