// =====================================
// Responsive Embed
// =====================================

.responsive-embed {
    position: relative;
    height: 0;
    max-width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
