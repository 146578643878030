2// =====================================
// Header
// =====================================

.header {
    background-color: #fff;
}

.header-container {
    @include site-outer;
}

.header-main {
    @include clearfix;
    padding: 1em 0;
}

.header-nav-toggle {
    float: right;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.header-nav-toggle-text {
    margin-right: 1em;
}

.header-links {
    display: none;
}

.header-logo {
    display: inline-block;;
    float: left;
    width: 100%;
    max-width: 135px;
}

.header-sub-nav {
    background-color: $colour-secondary;
}

.header-nav,
.header-sub-nav {
    display: none;
}

@include media(expanded-header) {

    .header-main {
        padding: 2em 0;
        border-bottom: 1px solid $colour-grey;
    }

    .header-nav,
    .header-sub-nav {
        display: block;
    }

    .header-logo {
        max-width: 210px;
    }

    .header-nav-items {
        display: flex;
        justify-content: space-between;
        table-layout: auto;
        text-align: left;
    }

    .header-nav-item {
        display: inline;
    }

    .header-nav-link {
        display: inline-block;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        padding: 2em 0;
        font-size: em(14);
        font-weight: 600;
        text-transform: uppercase;
    }

    .header-nav-link:hover,
    .header-nav-link.is-active {
        color: $colour-primary;
    }

    .header-nav-link.is-active {
        border-bottom-color: currentColor;
    }

    .header-nav-toggle {
        display: none;
    }

    .header-links {
        display: block;
        float: right;
    }

    .header-link {
        display: inline-block;
        margin-right: 3em;
        color: inherit;
    }

    .header-link:hover {
        color: $colour-primary;
    }

    .header-link:last-child {
        margin-right: 0;
    }

    .no-flexbox {
        
        .header-nav-items {
            width: 100%;
            display: table;
            table-layout: fixed;
            text-align: center;
        }

        .header-nav-item {
            display: table-cell;
        }
    }

}

.js {

    .header-main {
        background-color: rgba(255,255,255,0.98);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: z-index(fixed-header);
        transition: padding 200ms;
    }

    .header-main--collapsed {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .header-nav--hidden {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        visibility: hidden;
        transition: all 200ms;
        background-color: rgba(255,255,255,0.98);
        transform: translateY(-100%);
        z-index: z-index(fixed-header) - 100;
    }

    .header:hover .header-nav {
        transform: translateY(0);
        visibility: visible;
    }

}
