// =====================================
// FORM
// =====================================

.form,
.hs-form {
    @include clearfix;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

.form-group {
    border-top: 1px solid $colour-grey;
    padding-top: em(30);

    @include media(medium) {
        border: 1px solid $colour-grey;
        padding: 5%;
    }
}

.form-group--optional {
    display: none;
}

.form-group--optional.is-visible {
    display: block;
}

.form-instructions {
    margin-bottom: 2em;
}

// -------------------------------------
// Element
// -------------------------------------

.form-group,
.form-element,
.hs-form .hs-form-field {
    margin-bottom: 2.8em;
}

.form-control {
    display: table;
    width: 100%;
}

.form-control-button {
    display: table-cell;
    padding-left: 2em;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;

    .button {
        line-height: normal;
        padding: calc(0.5em + 2px);
    }
}

.form-label,
.hs-form label {
    display: inline-block;
    margin-bottom: em(10);
    font-size: em(18);
    font-weight: 600;
}

.form-element-help,
.hs-error-msgs label,
.hs-form .hs-field-desc {
    display: block;
    margin-top: 0.3em;
    font-weight: 400;
    font-size: 1em;
}

.hs-input.invalid.error {
    border-color: $colour-error;
}

// -------------------------------------
// States
// -------------------------------------

// Error
// -------------------------------------

.form-element.is-invalid {

    .form-label,
    .form-required,
    .form-element-help {
        color: $colour-error;
    }

    .form-input,
    .form-textarea {
        outline: 3px solid $colour-error;
    }

}

.hs-error-msgs label {
    color: $colour-error;
}


.form-required,
.hs-form-required {
    display: inline-block;
    margin-left: 0.8em;
    content: '*';
    color: $colour-primary;
    font-weight: 600;
}

.form-input,
.form-textarea,
.hs-form input:not([type="submit"]),
.hs-form textarea {
    padding: $form-input-padding;
    width: 100%;
    border: 1px solid $colour-grey;
    font-size: em(18);
    background-color: transparent;
}

.form-input:focus,
.form-textarea:focus,
.hs-form input:focus:not([type="submit"]),
.hs-form textarea:focus {
    outline: 3px solid $colour-primary;
}

.hs-button {
    @include button();
    float: right;
}
