// =====================================
// Feature
// =====================================

// Example cam be found on the homepage

.feature {
    margin-top: em(50);
    margin-bottom: em(50);
}

.feature-container {
    position: relative;
}

.feature-item {
    display: none;
}

.feature-summary {
    text-align: center;
}

@media (min-width: 30em) {
    .feature-items {
        @include clearfix;
        margin-bottom: em(50);
    }

    .feature-item {
        display: inline-block;
        float: left;
        width: 45%;
    }

    .feature-item--one {
        margin-right: 10%;
    }
}

@media (min-width: 60em) {
    .feature {
        margin-top: em(100);
        margin-bottom: em(100);
    }

    .feature-copy {
        max-width: 50%;
    }

    .feature-items {
        margin-bottom: 0;
    }

    .feature-item--one {
        float: left;
        margin-right: 0;
    }

    .feature-item--two {
        float: right;
    }

    .feature-item--two .feature-image,
    .feature-item--two .feature-copy {
        clear: both;
        float: right;
    }

    .feature-image {
        margin-bottom: em(50);
    }

    .feature-summary {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15%;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        max-width: 28em;
        width: 100%;
    }
}
