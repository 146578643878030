// =====================================
// Navigation
// =====================================

$navigation-width: 260px;

.navigation {
    background-color: $colour-slate;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: em(14);
}

.navigation-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.navigation-link {
    display: block;
    padding: em(15) em(20);
    flex-grow: 1;
    border-bottom: 1px solid $colour-mid-slate;
}

.navigation-link:hover ,
.navigation-link.is-active {
    color: $colour-tertiary;
}

.navigation-dropdown {
    display: flex;

    span {
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        padding: 0 em(20);
        background-color: $colour-mid-slate;
        border-bottom: 1px solid $colour-mid-slate;
    }

    span:hover {
        background-color: $colour-slate;
        border-left: 1px solid $colour-mid-slate;
        cursor: pointer;
    }

    .navigation-link:hover {
        border-right: 1px solid $colour-slate;
    }
}

.navigation-chevron-icon {
    fill: #fff;
    width: 10px;
    height: 6px;
    pointer-events: none;
}



// -------------------------------------
// Child
// -------------------------------------

.js {

    .navigation-items--child {
        background-color: $colour-mid-slate;

        .navigation-link {
            border-bottom: 1px solid $colour-slate;
        }
    }

}


.no-js .navigation-items--child {
    margin-left: 2em
}

.js .navigation-items--child {
    display: none;
}

.navigation-items--child.is-open {
    display: block;
}
