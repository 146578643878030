// =====================================
// Product
// =====================================

.product {
    @include clearfix;
    position: relative;
    margin-bottom: em(100);
}

.product-inner {
    position: relative;
    overflow: hidden; // Hide the line connecting the icon and callout
}

.product-image {
    width: 100%;
    height: auto;
}

.product-image-container {
    margin-bottom: em(50);
    text-align: center;
}

.product-detail-container {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.product-detail {
    position: absolute;
    display: none;
    width: 20px;
    height: 20px;
    background-color: $colour-primary;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
}

.product-detail:after {
    display: block;
    width: 40px;
    height: 40px;
    content: '';
    top: -10px;
    left: -10px;
    position: absolute;
}

.product-detail:focus {
    outline: 0;
}

.product-detail-icon {
    width: 8px;
    height: 8px;
}

.product-callouts {
    display: none;
}

.product-callout-media {
    margin-bottom: em(30);
}



// -------------------------------------
// JavaScript
// -------------------------------------

.js {

    @media (min-width: 60em) {
        .product {
            overflow: hidden;
        }

        .product-accordian {
            display: none;
        }

        .product-image-container {
            width: 60%;
            margin-right: 4%;
            margin-bottom: 0;
        }

        .product-detail {
            cursor: pointer;
            display: block;
        }

        .product-callouts {
            display: block;
            width: 40%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            margin: 0;
            padding: 0;
            -webkit-overflow-scrolling: touch;
        }

        .product-callout {
            display: none;
            padding: 3em 3em;
        }

        .product-callout.is-active {
            display: block;
        }

        .product-detail:hover,
        .product-detail.is-active {
            background-color: #fff;
            color: $colour-primary;
        }

        .product-detail.is-active:after {
            height: 4px;
            width: 1000px;
            position: relative;
            left: 100%;
            top: -53%;
            content: '';
            display: block;
            background-color: #fff;
        }

    }

}

// -------------------------------------
// Product icons
// -------------------------------------

// FROG
// -------------------------------------

#frog-buoyancy-pt {
    top: 70%;
    left: 70%;
}

#frog-medevac-pt {
    top: 65%;
    left: 40%;
}

#frog-harness-pt {
    top: 52%;
    left: 52%;
}

#frog-suspension-pt {
    top: 73%;
    left: 48%;
}

#frog-feet-pt {
    top: 88%;
    left: 20%;
}

#frog-frame-pt {
    top: 40%;
    left: 33%;
}

#frog-column-pt {
    top: 30%;
    left: 47.5%;
}

// FROG XT 4
// -------------------------------------

#xt4-buoyancy-pt {
    top: 30%;
    left: 65%;
}

#xt4-protection-pt {
    top: 25%;
    left: 32%;
}

#xt4-frame-pt {
    top: 56%;
    left: 61.5%;
}

#xt4-footprint-pt {
    top: 77%;
    left: 49%;
}

#xt4-loadplate-pt {
    top: 11%;
    left: 49%;
}

#xt4-feet-pt {
    top: 86%;
    left: 35%;
}

#xt4-harness-pt {
    top: 55%;
    left: 41%;
}

#xt4-suspension-pt {
    top: 62%;
    left: 47%;
}

#xt-4-performance-pt {
	top: 68%;
	left: 27%;
}

// FROG XT 4
// -------------------------------------

#xt6-buoyancy-pt {
    top: 70%;
    left: 68%;
}

#xt6-protection-pt {
    top: 43%;
    left: 75%;
}

#xt6-frame-pt {
    top: 37%;
    left: 36%;
}

#xt6-footprint-pt {
    top: 83%;
    left: 52%;
}

#xt6-loadplate-pt {
    top: 14%;
    left: 48%;
}

#xt6-feet-pt {
    top: 87%;
    left: 30%;
}

#xt6-harness-pt {
    top: 51%;
    left: 45%;
}

#xt6-suspension-pt {
    top: 70%;
    left: 47%;
}

#xt6-performance-pt {
	top: 61%;
    left: 25%;
}

#xt6-medevac-pt {
	top: 27%;
	left: 69%;
}

// FROG XT 10
// -------------------------------------

#xt-10-buoyancy-pt {
    top: 78%;
    left: 55%;
}

#xt-10-protection-pt {
    top: 25%;
    left: 25%;
}

#xt-10-frame-pt {
    top: 50%;
    left: 27%;
}

#xt-10-load-plate-pt {
    top: 15%;
    left: 50%;
}

#xt-10-feet-pt {
    top: 86%;
    left: 26%;
}

#xt-10-harness-pt {
    top: 40%;
    left: 65%;
}

#xt-10-suspension-pt {
    top: 70%;
    left: 39%;
}

#xt-10-performance-pt {
	top: 29%;
	left: 52%;
}

#xt-10-medevac-pt {
	top: 24%;
    left: 76%;
}

// WAVE
// -------------------------------------

#wave-footprint-pt {
    top: 80%;
    left: 55%;
}

#wave-feet-pt {
    top: 85%;
    left: 28%;
}

#wave-buoyancy-pt {
    top: 50%;
    left: 30%;
}

#wave-frame-pt {
    top: 60%;
    left: 65%;
}

#wave-stabilisers-pt {
    top: 24%;
    left: 61%;
}

#wave-loadplate-pt {
    top: 10%;
    left: 47.5%;
}

#wave-luggage-pt {
    top: 50%;
    left: 47.5%;
}

// TORO
// -------------------------------------

#toro-buoyancy-pt {
    top: 60%;
    left: 70%;
}

#toro-medevac-pt {
    top: 80%;
    left: 47.25%;
}

#toro-harness-pt {
    top: 50%;
    left: 41.5%;
}

#toro-feet-pt {
    top: 78%;
    left: 30%;
}

#toro-frame-pt {
    top: 37%;
    left: 73%;
}

#toro-column-pt {
    top: 13%;
    left: 47.25%;
}
