.l-sitemap {
    @include site-outer;
}

.l-sitemap-group {
    margin-bottom: em(50);

}

@include media(medium) {
    .l-sitemap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    .l-sitemap-group {
        width: 30%;
    }
}

@include media(large) {
    .l-sitemap {

    }
}
