// =====================================
// Stat
// =====================================

.stat {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.stat-helper {
    display: flex;
    align-items: center;
    color: $colour-primary;
    margin-bottom: 0.5em;
    text-align: center;
    justify-content: center;
}

.stat-value {
    font-size: em(44);
    font-weight: 600;
    color: inherit;
    line-height: 1;
}

.stat-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: em(14);
    max-width: 18em;
    display: inline-block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
}

.stat-icon {
    margin-right: 30px;
    fill: currentColor;
}

@media (min-width: 20em) {
    .stat-value {
        font-size: em(84);
    }
}

@include media(medium) {
    .stat-helper {
        margin-bottom: em(28);
    }

    .stat-value {
        font-size: em(100);
    }
}
