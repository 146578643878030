/**
 * Home Page Layout
 */

.l-home,
.layout-item,
.l-home-item-a,
.l-home-item-b,
.l-home-item-c,
.l-home-item-d {
    margin-bottom: em(50);
}

.l-home-item-a {
    text-align: center;
}

.l-home-item-a,
.l-home-item-d {
    max-width: em(890);
    margin-left: auto;
    margin-right: auto;
    width: $l-site-width;
}

.l-home-item-b {
    max-width: em(1140);
    margin-left: auto;
    margin-right: auto;
    width: $l-site-width;
}

.l-home-item-c {
    max-width: em(1360);
    margin-left: auto;
    margin-right: auto;
    width: $l-site-width;
}

.l-home-item-d {
    text-align: center;
}

.l-home-item-e {
    text-align: center;
}

/**
 * 1. Display inline-block will prevent margins from collapsing so set all items
 *    to block.
 */
.l-home .layout-item {
    display: block; // [1]
}

.feature-supporters {

    margin-top: em(50);

    .desktop-img {
        display: none;
    }
    
    img {
        margin-top: em(20);
        margin-bottom: em(10);
    }

    p:before {
        display: block;
        height: 3px;
        width: 30px;
        margin-bottom: em(20);
        background-color: #343e4a;
        content: '';
        margin-left: auto;
        margin-right: auto;
    }
}

@include media(small-medium) {
    .l-home-item-d {
        display: table;
        table-layout: fixed;
    }

    .l-home-item-d .layout-item {
        display: table-cell;
    }
}

@include media(medium) {

    .l-home,
    .l-home-item-a,
    .l-home-item-b,
    .l-home-item-c,
    .l-home-item-d {
        margin-bottom: em(100);
    }

    .l-home .layout-item {
        margin-bottom: 0;
    }

    .l-home-item-c {
        @include clearfix;
    }

    .l-home-item-c .layout-item {
        margin-bottom: 0;
        float: left;
        width: 31%;
        margin-right: 2.5%;
    }

    .l-home-item-c .layout-item:last-child {
        margin-right: 0;
    }

    .flexbox {

        .l-home-item-c {
            display: flex;
        }

        .l-home-item-c .layout-item {
            float: none;
        }

    }

    .feature-supporters {

        clear: both;

        .mobile-img {
            display: none;
        }

        .desktop-img {
            display: inline-block;
        }

        p {
            width: 40%;
            margin: auto;
            text-align: center;
        }

        img {
            margin-top: em(50);
            margin-bottom: 0;
        }
    }

}
