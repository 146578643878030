@mixin type($style:null) {

    @if ($style == 'heading-three') {
        font-size: em(22);
    	line-height: 1.3;
    	letter-spacing: -0.01em;
    	text-transform: uppercase;
    	margin-bottom: 1em;

    	@include media(medium) {
    		font-size: em(30);
    		margin-bottom: 1.43em;
    	}
    }

}
