// =====================================
// Variables
// =====================================

// -------------------------------------
// Layout
// -------------------------------------

$site-gutter: 5; // As a percentage
$site-outer-width: 1360; // In px
$site-inner-width: 670; // In px
$l-site-width: 100 - (2 * $site-gutter) * 1%;

// -------------------------------------
// Typography
// -------------------------------------

$line-height: 1.5;
$leading: $line-height * 1em;

// -------------------------------------
// Colours
// -------------------------------------

$colour-slate: #343e4a;
$colour-mid-slate: #3f4853;
$colour-primary: #0090c3;
$colour-secondary: #cce9f3;
$colour-tertiary: #7ac2dd;
$colour-grey: #cacbcd;
$colour-light-grey: #f4f5f7;
$colour-error: #be1a21;

$body-colour: $colour-slate;
$body-background-colour: $colour-light-grey;

// -------------------------------------
// Media Queries
// -------------------------------------

$media-queries: (
    hidpi: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
    small: "(min-width: 40em)",
    small-medium: "(min-width: 50em)",
    medium: "(min-width: 60em)",
    large: "(min-width: 80em)",
    layout-two-large: "(min-width: 1500px)",
    expanded-header: "(min-width: 50em)",
    footer-medium: "(min-width: 50em)"
);

// -------------------------------------
// z-index
// ------------------------------------

$z-index-map: (
    offcanvas-menu: 10,
    offcanvas-body: 100,
    billboard-helper: 110,
    sticky-nav: 110,
    billboard-image: 120,
    bigshot: 200,
    fixed-header: 500
);

// -------------------------------------
// Forms
// -------------------------------------

$form-input-padding: 0.35em;
$form-input-border: 1px solid $colour-grey;

// -------------------------------------
// Hamburgers
// -------------------------------------

$hamburger-padding-x                       : 0;
$hamburger-padding-y                       : 0;
$hamburger-layer-width                     : 30px;
$hamburger-layer-height                    : 4px;
$hamburger-layer-spacing                   : 6px;
$hamburger-layer-color                     : currentColor;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;
