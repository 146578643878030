.l-fullscreen {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.l-fullscreen-content {
    @include site-inner;
    margin-top: em(50);
    margin-bottom: em(50);
}
