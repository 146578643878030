// =====================================
// Typography
// =====================================

@font-face {
	font-family: 'Proxima-Nova';
	font-weight: bold;
	font-style: normal;
	src: url('/dist/fonts/proxima_nova/30797C_0_0.eot');
	src: url('/dist/fonts/proxima_nova/30797C_0_0.eot?#iefix') format('embedded-opentype'),url('/dist/fonts/proxima_nova/30797C_0_0.woff2') format('woff2'),url('/dist/fonts/proxima_nova/30797C_0_0.woff') format('woff'),url('/dist/fonts/proxima_nova/30797C_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Proxima-Nova';
	font-weight: normal;
	font-style: normal;
	src: url('/dist/fonts/proxima_nova/30797C_1_0.eot');
	src: url('/dist/fonts/proxima_nova/30797C_1_0.eot?#iefix') format('embedded-opentype'),url('/dist/fonts/proxima_nova/30797C_1_0.woff2') format('woff2'),url('/dist/fonts/proxima_nova/30797C_1_0.woff') format('woff'),url('/dist/fonts/proxima_nova/30797C_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Proxima-Nova';
	font-weight: normal;
	font-style: italic;
	src: url('/dist/fonts/proxima_nova/30797C_2_0.eot');
	src: url('/dist/fonts/proxima_nova/30797C_2_0.eot?#iefix') format('embedded-opentype'),url('/dist/fonts/proxima_nova/30797C_2_0.woff2') format('woff2'),url('/dist/fonts/proxima_nova/30797C_2_0.woff') format('woff'),url('/dist/fonts/proxima_nova/30797C_2_0.ttf') format('truetype');}


body {
	color: $body-colour;
	font-family: 'Arial', sans-serif;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fonts-loaded body {
	font-family: 'Proxima-Nova', 'Arial', sans-serif;
	letter-spacing: -0.01em;
	line-height: $line-height;
}

p {
	margin-top: 0;
	font-size: em(18);
	margin-bottom: $leading;
}

a {
	text-decoration: none;
	color: inherit;
}

.type-lead {
	font-size: em(21);

	@include media(medium) {
		font-size: em(26);
	}
}

.t-muted {
	text-transform: uppercase;
	font-weight: 600;
	color: $colour-tertiary;
}

.t-action-link {
	font-weight: 600;
	text-transform: uppercase;
	color: $colour-primary;
}

.t-action-link:hover {
	color: $colour-grey;
}

// -------------------------------------
// Blockquote
// -------------------------------------

blockquote {
	margin: 0;
	padding-left: 2.5em;
	font-size: em(18);
	font-style: italic;
	border-left: 3px solid $colour-primary;
	margin-bottom: em(50);
	margin-top: em(50);
	position: relative;

	@include media(medium) {
		font-size: em(21);
		line-height: 1.7;
		max-width: 26em;
		padding-left: 5em;
	}
}


// -------------------------------------
// Headings
// -------------------------------------

h1, h2, h3, h5, h5, h6 {
	margin: 0;
}

.heading-one,
.heading-two,
.heading-three,
.heading-four {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-weight: 600;
}

// Heading one
// -------------------------------------

.heading-one {
	margin-bottom: em(40,21);
	font-size: em(21);
	line-height: 1.2;
	letter-spacing: -0.01em;
	text-transform: uppercase;

	@include media(medium) {
		margin-bottom: em(75,48);
		font-size: em(48);
	}
}

.heading-one--rule::before {
	display: block;
	height: 3px;
	margin-bottom: em(30,21);
	width: 60px;
	content: '';
	background-color: currentColor;

	@include media(medium) {
		margin-bottom: em(30,48);
	}
}

// Heading two
// -------------------------------------

.heading-two {
	margin-bottom: em(20,21);
	font-size: em(21);
	line-height: 1.2;
	letter-spacing: -0.01em;

	@include media(medium) {
		margin-bottom: em(30,38);
		font-size: em(38);
	}
}

// Heading three
// -------------------------------------

.heading-three {
	font-size: em(22);
	line-height: 1.3;
	letter-spacing: -0.01em;
	text-transform: uppercase;
	margin-bottom: rem(50);

	@include media(medium) {
		font-size: em(30);
	}
}

.heading-three--rule {
	text-align: center;
}

.heading-three--rule:after {
	content: '';
	display: block;
	height: 3px;
	margin-left: auto;
	margin-right: auto;
	margin-top: rem(20);
	width: 60px;
	background-color: $colour-slate;
}

// Heading four
// -------------------------------------

.heading-four {
	font-size: em(18);
	line-height: 1.3;
	letter-spacing: -0.01em;
	text-transform: uppercase;
	margin-bottom: em(18,18);

	@include media(medium) {
		font-size: em(21);
		margin-bottom: em(18,21);
	}
}

.heading-four--rule:before,
.heading-four--rule-centered:before {
	display: block;
    height: 3px;
    width: 30px;
    margin-bottom: em(20);
    background-color: #343e4a;
    content: '';
}

.heading-four--rule-centered:before {
	margin-left: auto;
	margin-right: auto;
}

.t-link:hover {
	color: $colour-primary;
}

// -----------------------------------------------------------------------------
// Typeset
// -----------------------------------------------------------------------------

.typeset {

	// Headings
	// -------------------------------------------------------------------------

	.heading-three {
		margin-top: rem(50);
	}

	.heading-four {
		margin-top: 2em;
	}

	// Links
	// -------------------------------------------------------------------------

	a:not(.button) {
		color: $colour-primary;
		font-weight: 600;
	}

	a:hover:not(.button) {
		color: $colour-grey;
	}

	// Lists
	// -------------------------------------------------------------------------

	ul {
		list-style: none;
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		margin-bottom: em(50);
	}

	ul ul {
		margin-bottom: 0;
	}

	ul li {
		padding-left: 1.1em;
		text-indent: -1.2em;
	}

	ul li:before {
		color: $colour-primary;
		font-size: 1.4em;
		margin-right: 0.5em;
		content: '\2022';
	}

	li {
		margin-bottom: 1.3em;
		font-size: em(18);
	}

}

.typeset--rtl {
	direction: rtl;

	ul li {
		padding-left: 0;
		padding-right: 1.1em;
		text-indent: -1.2em;
	}

	ul li:before {
		margin-right: 0;
		margin-left: 0.5em;
	}
}
