// =====================================
// Buttons
// =====================================

// src/styles/lib/mixins/buttons

.button {
    @include button;
}

// Contrast
// -------------------------------------

.button--contrast {
    border-color: #fff;
    color: #fff;

    &:hover {
        background-color: #fff;
        color: $colour-slate;
    }

}

// Small
// -------------------------------------

.button--small {
     padding: 0.4em 1em;

    @include media(medium) {
        padding: 0.4em 1em;
    }
}
