// =====================================
// Info Card
// =====================================

// Base
// =====================================

.panel {
    margin-bottom: em(30);
    background-color: #fff;
}

.panel--top {
    background-color: transparent;
    margin-bottom: 0;
    margin-top: 0;

    .panel-header {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

	.panel-body {
		padding-left: 0;
		padding-right: 0;
	}
}

// Header & Body
// -------------------------------------

.panel-body,
.panel-header {
    padding-left: 5%;
    padding-right: 5%;
}

// Header
// -------------------------------------

.panel-header {
    display: flex;
    align-items: center;
	flex-wrap: wrap;
    padding-top: em(10);
    padding-bottom: em(10);
    border-bottom: 1px solid $colour-grey;
}

.panel-header-title {
    @include type(heading-three);
    display: inline-block;
    margin-right: 5%;
    max-width: 90%;
    margin-bottom: 0!important;
}

.panel-header-link {
    float: right;
    margin-left: auto;
}

.panel-header-dropdown {
	width: 100%;
}

// Body, Primary, Secondary, Item
// -------------------------------------

.panel-body {
    padding-top: em(50);
    padding-bottom: em(50);
    font-size: rem(14);
}

.panel-row:not(:last-child) {
	margin-bottom: em(50);
}

// Links
// -------------------------------------

.panel-link {
    font-weight: 600;
	white-space: nowrap;

    &:hover {
        color: $colour-primary;
    }
}

// Title
// -------------------------------------

.panel-title {
    margin-bottom: 0.8em;
    font-size: rem(18);
    font-weight: 600;
    text-transform: uppercase;
}

// Description
// -------------------------------------

.panel-description {
    font-size: rem(16);
}

// Services
// -------------------------------------

.panel-service {
    display: inline-block;
    color: $colour-grey;
    text-align: center;
}

.panel-service:not(:last-child) {
    margin-right: 1em;
}

.panel-service.is-available {
    color: $colour-primary;
}

.panel-service-label {
    display: block;
    color: inherit;
    font-weight: 600;
    text-transform: uppercase;
}

// Icon
// -------------------------------------

.panel-icon {
    display: block;
    height: 48px;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    width: 55px;
}

// Point of Contact
// -------------------------------------

.panel-poc {
    display: block;
}

.panel-poc-icon {
    margin-right: 1.5em;
}

// Address
// -------------------------------------

.panel-address {
	line-height: 2;
}

.panel--team {

	.panel-item-a {
		margin-bottom: 0.8em;
	}

	.panel-member:not(:last-child) {
		margin-bottom: 1em;
	}

}

.panel--contact {

	.panel-item-a {
		margin-bottom: 1em;
	}

}

.panel--clients {

	.panel-description {
		margin-bottom: 0.8em;
	}

}

// Breakpoint
// =====================================

@media (min-width: 50em) {

	.panel--top {

		.panel-item-a {
			float: left;
			width: 47.5%;
			margin-right: 5%;
		}

		.panel-item-b {
			float: left;
			width: 47.5%;
		}

		.panel-item-c {
			clear: right;
		}

	}

	.panel--contact {

		.panel-item-a,
		.panel-item-b {
			@include clearfix;
		}

		.panel-item-a-1,
		.panel-item-a-2,
		.panel-item-b-1,
		.panel-item-b-2 {
			float: left;
			width: 47.5%;
		}

		.panel-item-a-1,
		.panel-item-b-1 {
			margin-right: 5%;
		}

	}

	.panel--team {

		.panel-item-b {
			@include clearfix;
		}

		.panel-member {
			float: left;
			width: 47.5%;
		}

		.panel-member:nth-child(odd) {
			margin-right: 5%;
		}
		
	}

}

// Breakpoint
// =====================================

@media (min-width: 80em) {

	.panel--top {

		.panel-body {
			@include clearfix;
		}

		.panel-item-a {
			float: left;
			width: 20%;
		}

		.panel-item-b {
			width: 20%;
			margin-right: 5%;
		}

		.panel-item-c {
			float: left;
			width: 50%;
		}

	}

	.panel--contact {

		.panel-row {
			@include clearfix;
		}

		.panel-item-a,
		.panel-item-b {
			float: left;
			width: 47.5%;
		}

		.panel-item-a {
			margin-right: 5%;
			margin-bottom: 0;
		}

		.panel-item-b-2 {
			float: right;
		}

	}

	.panel--clients {

		.panel-row {
			@include clearfix;
		}

		.panel-item-a,
		.panel-item-b,
		.panel-item-c {
			float: left;
		}

		.panel-item-a {
			width: 20%;
			margin-right: 5%;
		}

		.panel-item-b {
			width: 50%;
			margin-right: 5%;
		}

		.panel-item-c {
			width: 20%;
		}

	}

	.panel--team {

		.panel-row {
			@include clearfix;
		}

		.panel-item-a,
		.panel-item-b,
		.panel-item-b-1,
		.panel-item-b-2 {
			float: left;
		}

		.panel-item-a {
			width: 55%;
			margin-right: 5%;
		}

		.panel-item-b {
			width: 40%;
		}

		.panel-item-b-1 {
			width: 35%;
			margin-right: 5%;
		}

		.panel-item-b-2 {
			width: 60%;
		}

		.panel-member {
			width: 100%;

			&:nth-child(odd) {
				margin-right: 0;
			}
		}

	}

	.panel-header--filter {

		.panel-item-a {
			float: left;
			width: 55%;
			margin-right: 5%;
		}

		.panel-item-b {
			float: left;
			width: 40%;
		}

	}

}
