.child-listing {
    max-width: em(1130);
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.child-listing-header {
    max-width: em(670);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: em(50);
}

.child-listing-item {
    margin-bottom: em(50);
    background-color: #fff;
}

.child-listing-body {
    padding: 5%;
    text-align: center;
}

.child-listing-image img {
    max-width: 100%;
    width: 100%;
}

.child-listing-secondary-content {
    display: none;
}

// media query is also used in src/scripts/child-listing.js
@include media(small-medium) {

    .child-listing-header {
        margin-bottom: em(100);
    }

    .child-listing-item {
        @include clearfix;
        position: relative;
    }

    .child-listing-body,
    .child-listing-image {
        width: 50%;
        float: left;
    }

    .child-listing-image {
        min-height: 400px;
    }

    .child-listing-item:nth-child(even) {
        .child-listing-image {
            float: right;
        }
    }

    .child-listing-secondary-content {
        display: block;
    }

    .flexbox {

        .child-listing-item:nth-child(even) {
            .child-listing-image {
                order: 1;
            }
        }

        .child-listing-item {
            display: flex;
        }

        .child-listing-body {
            align-self: center;
        }

    }

}
