// =====================================
// Table
// =====================================

// -------------------------------------
// Container
// -------------------------------------

.table-container {
    padding-top: em(75);
    padding-bottom: em(75);
}

.table-container--contrast {
    background-color: #fff;
}

// -------------------------------------
// Base - Shared amoung all tables
// -------------------------------------

.table table {
    width: 100%;
    table-layout: fixed;
    font-size: rem(18);
}

.table-strong {
    font-weight: 600;
}

.table-head th {
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    vertical-align: top;
}

.table-cell {
    padding-top: 1em;
    padding-bottom: 1em;
}

.table-cell:not(:last-child) {
    padding-right: 1em;
}

.table-cell-title {
    display: none;
    font-weight: 600;
    text-transform: uppercase;
}

.table-cell-label {
	display: block;
	font-weight: bold;
}

.table-label-value:not(:last-child) {
	margin-bottom: 1em;
}

.table-cell-content li {
	margin-bottom: 0.5em;
}

td {
    vertical-align: top;
}


// Stacked
// -------------------------------------

.table.is-stacked {

    tr, th, td {
        display: block;
        width: 100%;
        clear: left;
        float: left;
    }

    .table-head {
        display: none;
    }

    .table-cell {
        padding: 0;
    }

    .table-cell--secondary {
        display: none;
    }

    .table-cell-title {
        display: block;
        margin-bottom: 1em;
    }

}










// -------------------------------------
// Basic
// -------------------------------------

.table--basic.is-stacked {

    .table-row:not(:last-child) {
        border-bottom: 1px solid $colour-grey;
        margin-bottom: 2.2em;
        padding-bottom: 2em;
    }

    .table-cell {
        margin-bottom: 1em;
    }

}










// -------------------------------------
// Product Table
// -------------------------------------

.table--products {
    font-size: em(14);
    table-layout: auto;

	.table-head,
	.table-row:not(:last-child) {
		border-bottom: 1px solid $colour-grey;
	}

	.table-head .table-cell {
		padding-bottom: em(15);
	}

	.table-body .table-cell {
		padding-top: em(50);
		padding-bottom: em(50);
	}
}

.table-product {
    min-width: 10em;
}

.table-product-image-container {
    display: none;
}

.table-product-title {
	margin-bottom: 0.5em;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
}

.table-product-link {
    @include link;
    display: block;
    font-size: inherit;
	margin-top: 1em;
}

.table-product-logo {
	margin: em(12) 0;
}

.table-product-icon {
	width: em(35);
	height: em(35);
	fill: #4f4c4c;
}

@media (min-width: 80em) {

    .table-product.table-product {
        width: 28%;
        padding-right: 2%;
    }

    .table-product-text,
    .table-product-image-container {
        display: inline-block;
        float: left;
    }

    .table-product-text {
        width: 35%;
        vertical-align: top;
    }

    .table-product-image-container {
        text-align: center;
        width: 60%;
    }

    .table-product-image {
        max-height: 165px;
    }

}




// Stacked
// -------------------------------------

.table--products.is-stacked {

    .table-product {
        margin-bottom: 2em;
        display: flex;
        align-items: center;
    }

    .table-cell-title,
    .table-cell-content,
    .table-product-text,
    .table-product-image-container {
        display: inline-block;
        float: left;
        text-align: left;
    }

    .table-product-image {
        max-height: 120px;
    }

    .table-cell-title,
    .table-product-image-container {
        width: 40%;
        margin-right: 10%;
    }

    .table-cell {
        padding: 0;
    }

    .table-cell:not(:last-child):not(.table-product) {
        margin-bottom: 1em;
    }

    .table-cell-title {
        margin-bottom: 0;
    }

    .table-cell-content,
    .table-product-text {
        width: 50%;
    }

    .table-product {
        vertical-align: bottom;
    }

    .table-row:not(:last-child) {
        border-bottom: 1px solid $colour-grey;
        padding-bottom: 2em;
        margin-bottom: 2.3em;
    }

}

.table-toggle {
    @include link;
    margin-top: 1em;
    padding-left: $site-gutter * 1%;
    padding-right: $site-gutter * 1%;
    font-size: em(18, 16);
}

.table-header {
    display: flex;
    align-items: center;
    padding-left: $site-gutter * 1%;
    padding-right: $site-gutter * 1%;
    margin-bottom: em(20);
}

.table-header-title {
    @include type(heading-three);
    display: inline-block;
    margin-right: 5%;
    max-width: 90%;
    margin-bottom: 0!important;
}

.table-header-link {
    float: right;
    margin-left: auto;
}




// -------------------------------------
// Comparison
// -------------------------------------

.table--comparison {

    margin-bottom: em(85);

    .table-cell {
        text-align: center;
    }

    .table-cell:first-child,
    .table-cell:last-child {
        text-align: left;
    }

    .table-body {
        border-bottom: 1px solid $colour-grey;
    }

}

// Show all but the first five items subject to the component being enhanced with JavaScript.
.table--comparison.is-enhanced {

    .table-row:nth-child(n+6) {
        display: none;
    }

    .table-toggle:not(.table-toggle--stacked) {
        display: block;
    }

}

// Stacked
// -------------------------------------

.table--comparison.is-stacked.is-enhanced {

    .table-row:nth-child(n+3) {
        display: none;
    }

}

.table--comparison.is-stacked {

    margin-bottom: em(50);

    // We inherit the base styles for stacked tables.

    .table-cell {
        text-align: left;
    }

    .table-cell:not(:last-child) {
        margin-bottom: 0.8em;
    }

    .table-cell-title,
    .table-cell-content {
        display: inline-block;
        float: left;
    }

    .table-cell-title {
        width: 60%;
        margin-right: 10%;
        margin-bottom: 0;
    }

    .table-cell-content {
        width: 30%;
    }

}

.table--stripped {

    .table-cell {
        padding: 1.3em 0;
    }

    .table-head,
    .table-row:nth-of-type(even) {
        background-color: #fff;
    }

    .table-cell:first-child {
        padding-left: $site-gutter * 1%;
    }

    .table-cell:last-child {
        padding-right: $site-gutter * 1%;
    }

}

// Row hover state
.table--stripped-hover .table-body .table-row:hover{
    background-color: $colour-grey;
    cursor: pointer;
}

.table--stripped.is-stacked {

    .table-row {
        padding-top: em(40);
        padding-bottom: em(40);
    }

    .table-row:nth-of-type(even) {
        background-color: inherit;;
    }

    .table-row:nth-of-type(odd) {
        background-color: #fff;
    }

    .table-cell {
        padding: 0 5%;
    }

}


.table--downloads.is-stacked {

    .table-cell {
        padding-bottom: 0.8em;
    }

}

.table.is-expanded {

    .table-row {
        display: table-row!important;
    }

}


.table:not(.is-stacked) {
    .table-toggle {
        display: none;
    }
}



// -------------------------------------
// Form
// -------------------------------------

.filter {
    margin-bottom: 2em;
}

.filter-input {
    width: 100%;
}

.filter-form:not(:last-child) {
    margin-bottom: 1.5em;
}

.filter-title {
    @extend .heading-three;
    display: block;
    margin-bottom: 1em;
}

.filter-group,
.filter-section {
    margin-bottom: 1.8em;
}

.filter-label {
    @extend .heading-four;
    display: block;
    margin-bottom: 0.8em;
}

.filter-section--search .filter-label {
    display: none;
}


@media (min-width: 40em) {

    .filter {
        display: table;
        width: 100%;
    }

    .filter-form {
        display: table;
        width: 100%;
    }

    .filter-group {
        margin-bottom: 0;
    }

    .filter-section--category .filter-group {
        float: left;
        width: 48%
    }

    .filter-section--category .filter-group:nth-of-type(odd) {
        margin-right: 4%;
    }

    .filter-button {
        display: table-cell;
        vertical-align: bottom;
        white-space: nowrap;
        width: 1%;
        padding-left: 4%;
    }

}

@media (min-width: 65em) {

    .filter-title {
        margin-bottom: 0.3em;
    }

    .filter-section {
        display: table-cell;
        vertical-align: bottom;
    }

    .filter-section--category {
        width: 60%;
        padding-right: 10%;
    }

    .filter-section--search .filter-label {
        display: block;
        visibility: hidden;
    }

    .filter-section--search {
        width: 40%;
    }

}
