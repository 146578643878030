// =====================================
// Buttons
// =====================================

@mixin button($modifier:null) {
    display: inline-block;
    padding: 0.4em 1em;
    appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: 3px solid $colour-primary;
    color: $colour-primary;
    font-size: em(14);
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    transition: color 100ms, background-color 100ms;

    &:hover {
        color: #fff;
        background-color: $colour-primary;
    }

    @include media(medium) {
        padding: 1em 2em;
    }

    @if ($modifier == 'contrast') {

        border-color: #fff;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $colour-slate;
        }
    }

    @if ($modifier == 'small') {
        padding: 0.4em 1em;

        @include media(medium) {
            padding: 0.4em 1em;
        }
    }
    
}
