// =====================================
// Pagination
// =====================================

.pagination {
    @include clearfix;
    display: flex;
    align-items: center;
    margin-top: em(30);
    margin-bottom: em(30);
    width: 100%;
}

.pagination-pages {
    display: none;
    font-size: em(14);
    text-transform: uppercase;
}

.pagination-number {
    font-weight: 600;
}

.pagination-button.is-hidden {
    visibility: hidden;
}

.pagination-prev {
    float: left;
    margin-right: auto;
}

.pagination-next {
    float: right;
    margin-left: auto;
}

@media (min-width: 25em) {
    .pagination {
        text-align: center;
    }

    .pagination-pages {
        display: inline-block;
    }
}

@include media(medium) {
    .pagination-pages {
        font-size: em(21);
    }
}
