// =====================================
// COMMENT
// =====================================

.comment {
    margin-bottom: em(45);
    padding-top: em(45);
    border-top: 1px solid $colour-grey
}

.comment-form {
    margin-bottom: em(45);
}

.comment-author {
    @extend .heading-four;
    display: block;
    margin-bottom: 1em;
}

.comment-date {
    display: block;
    margin-bottom: 1em;
}
