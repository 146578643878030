// =====================================
// Card
// =====================================

.card {
    background-color: #fff;
    text-align: center;
    padding: 2em 1.5em;
}

.card-icon {
    margin-bottom: em(50);
}

.card-title {
    font-size: em(18);
    text-transform: uppercase;
    margin-bottom: 1em;
}

.card-title--rule:before {
    display: block;
    height: 3px;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: em(20);
    background-color: $body-colour;
    content: '';
}

.card-title--left-rule:before {
    display: block;
    height: 3px;
    width: 30px;
    margin-bottom: em(10);
    background-color: $body-colour;
    content: '';
}

.card-image {
    width: 100%;
}

.card-body {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@include media(large) {
    .card-title {
        font-size: em(21);
    }
}

@include media(medium) {
    .secondary-content {
        display: block;
    }
}
