// =====================================
// Billboard
// =====================================

.billboard {
    position: relative;
    margin-bottom: em(50);
    padding-top: em(50);
    padding-bottom: em(50);
    background-color: $colour-slate;
    color: #fff;
}



.billboard-outer {
    @include site-outer;
    position: relative;
}

.billboard-inner {
    @include site-inner;
}

.billboard-title {
    text-transform: uppercase;
    margin-bottom: 0;
}

.billboard-description {
    margin-bottom: 0;
    margin-top: em(50,18);
    font-size: em(18);
}

.billboard-description a {
	color: $colour-tertiary;

	&:hover {
		color: inherit;
	}
}

.billboard-date {
    display: block;
    margin-bottom: em(50,21);
    font-size: em(21);
}

// -------------------------------------
// Links
// -------------------------------------

.billboard-links {

    margin-top: em(50);

    .billboard-link + .billboard-link:before {
        margin-left: 0.9em;
        margin-right: 1em;
        content: '|';
        color: $colour-grey;
    }

}

.billboard-link {
    display: inline-block;
    margin-bottom: 1em;
    color: $colour-tertiary;
    font-weight: 600;
    text-transform: uppercase;
}

.billboard-link:hover {
    color: inherit;
}

// -------------------------------------
// Modifiers
// -------------------------------------

// Product
// -------------------------------------

.billboard--product {

    padding-bottom: 0;

    .billboard-helper {
        position: relative;
        z-index: z-index(billboard-helper);
    }

    .billboard-helper:after {
        position: absolute;
        top: 55%;
        bottom: 0;
        display: block;
        width: 100%;
        content: '';
        background-color: $body-background-colour;
    }

    .billboard-image__wrapper {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .billboard-image {
        position: relative;
        z-index: z-index(billboard-image);
    }

}

@include media(medium) {

    .billboard {
        margin-bottom: em(100);
        padding-top: em(100);
        padding-bottom: em(100);
    }

    .billboard-description {
        font-size: em(21);
        margin-top: em(50,21);
    }

    // Product
    // -------------------------------------

    .billboard--product {
        padding-bottom: inherit;
    }

}

@include media(large) {

    // Product
    // -------------------------------------

    .billboard--product {
        padding-bottom: inherit;

        .billboard-image {
            max-width: initial;
            width: 100%;
        }
    }
}
