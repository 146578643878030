// =====================================
// Icons
// =====================================

.icon-chevron {
    fill: currentColor;
    width: 10px;
    height: 23px;
}

.icon-safety {
    width: 40px;
    height: 40px;
}

.icon-support {
    width: 40px;
    height: 40px;
}

.icon-downloads {
    width: 40px;
    height: 40px;
}

.icon-thermometer {
    width: 17px;
    height: 45px;
}

.icon-clock {
    width: 40px;
    height: 40px;
}

.icon-fax {
    width: 25px;
    height: 32px;
}

.icon-twitter {
    height: 13px;
    width: 16px;
}

.icon-facebook {
    height: 18px;
    width: 9px;
}

.icon-youtube {
    width: 19px;
    height: 13px;
}

.icon-linkedin {
    width: 16px;
    height: 15px;
}

.icon-fax {
    height: 20px;
    width: 16px;
}

.icon-phone {
    height: 23px;
    width: 14px;
}

.icon-email {
    height: 16px;
    width: 19px;
}

.icon-link {
    width: 16px;
    height: 16px;
}

.icon-up {
    height: 20px;
}

.icon-tick {
    width: 17px;
    height: 14px;
}

.icon-cross {
    width: 16px;
    height: 16px;
}
