// =====================================
// Utilities
// =====================================

.u-clearfix {
	@include clearfix;
}

.u-bold {
	font-weight: bold;
}

.u-float-right {
	float: right!important;
}

.u-float-left {
	float: left!important;
}

.u-text-center,
.u-text-align-center {
	text-align: center!important;
}

.u-text-left,
.u-text-align-left {
	text-align: left!important;
}

.u-text-right,
.u-text-align-right {
	text-align: right!important;
}

.u-text-none {
	text-transform: none!important;
}

.u-text-uppercase {
	text-transform: uppercase!important;
}

.u-text-lowercase {
	text-transform: lowercase!important;
}

.u-screen-reader {
	border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.u-screen-reader:focus,
.u-screen-reader:active {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.u-fluid {
	width: 100%;
}

.u-hidden {
	visibility: hidden;
}

.u-mb-none {
	margin-bottom: 0;
}
