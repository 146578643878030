// =====================================
// Complex
// =====================================

.l-article {
    @extend .l-base;
    max-width: em(1500);
    margin-left: auto;
    margin-right: auto;
    width: $l-site-width;
}

.l-article--noMargin {
    margin-top: 0;
    margin-bottom: 0;
}

.l-article-aside {
    display: none;
}

.l-article-outset-left,
.l-article-outset-left-wide {
    margin-top: em(50);
    margin-bottom: em(50);
}

.l-article-narrow {
    max-width: em(670);
    margin-left: auto;
    margin-right: auto;
}

.l-article-outset-left {
    max-width: em(900);
    margin-left: auto;
    margin-right: auto;
}

.l-article-outset-left-wide {
    max-width: em(1015);
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1200px) {
    .l-article--noMargin {
        margin-top: 0!important;
        margin-bottom: 0!important;
    }

    .l-article-outset-left,
    .l-article-outset-left-wide {
        margin-top: em(100);
        margin-bottom: em(100);
    }

    .l-article-narrow {
        margin-left: 26%;
        margin-right: 26%;
        max-width: 100%;
    }

    .l-article-aside {
        display: block;
    }

    .l-article-outset-left {
        margin-right: 26%;
        margin-left: 10%;
        max-width: 100%;
    }

    .l-article-outset-left-wide {
        margin-right: 26%;
        max-width: 100%;
    }

}
