// =====================================
// Base
// =====================================

.l-base,
%l-base {
    margin-top: em(50);
    margin-bottom: em(50);

    @include media(medium) {
        margin-top: em(100);
        margin-bottom: em(100);
    }
}
