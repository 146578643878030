
/**
 * [1] Account for fixed header. This value is a rough guest while we wait for
 * JS to calc the actual height. This inital padding avoids the page jump while
 * we wait for this to happen.
 */

.js .offcanvas-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: $navigation-width;
    z-index: z-index(offcanvas-menu);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    z-index: 0;
    background-color: $colour-slate;
    border-left: 1px solid $colour-mid-slate;
    color: #fff;
}

.js .offcanvas-body {
    position: relative;
	height: 100%;
	transition: transform 300ms;
	z-index: z-index(offcanvas-body);
    background-color: $body-background-colour;
    padding-top: 64px; // [1]

	&.is-open {
		transform: translateX(-$navigation-width);
	}

    @include media(expanded-header) {
        padding-top: 89px; // [1]
    }
}

.offcanvas-menu-contact {
    text-transform: none;
    font-weight: 400;
    padding: em(15) em(20);
    margin-top: 3em;
}

.offcanvas-link {
    display: block;
    margin-bottom: 0.5em;
}

.offcanvas-link:hover {
    color: $colour-tertiary;
}
