// =====================================
// Video
// =====================================

.video-play {
    border-radius: 50%;
    background-color: #fff;
    color: $colour-primary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.video-play:hover {
    background-color: $colour-primary;
    color: #fff;
    cursor: pointer;
}

.video-play-icon {
    width: 20px;
    height: 20px;
    top: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
    position: absolute;
}
