.sub-nav-link,
.sub-nav-toggle {
    font-size: em(14);
    font-weight: 600;
    text-transform: uppercase;

    &:hover,
    &.is-active {
        color: $colour-primary;
    }
}

.sub-nav-inner {
    @include site-outer;
    @include clearfix;
    position: relative;
    padding: 1.5em 0;
}

.sub-nav-items {
    float: left;
}

.sub-nav-item {
    display: inline-block;
}

.sub-nav-dropdown-wrapper {
    float: right;
}

.sub-nav-item {
    margin-right: 2.5em;
}

.sub-nav-dropdown {
    right: -1em;
    top: 100%;
    z-index: 50;
    background: #fff;
    width: 15em;

    .sub-nav-item {
        white-space: normal;
        display: block;
        margin-right: 0;
    }

    .sub-nav-link {
        white-space: normal;
        display: block;
        padding: 0.6em 1.6em;
    }

    .sub-nav-link:hover {
        background-color: $colour-grey;
        color: inherit;
    }
}

.sub-nav-dropdown::before {
    content: '';
    position: absolute;
    top: -15px;
    right: 25px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #fff transparent;
}
