// =====================================
// Bigshoot
// =====================================

.bigshot {
    position: relative;
    margin-top: em(50);
    margin-bottom: em(50);
    z-index: z-index(bigshot);
}

.bigshot-image {
    width: 100%;
}

.bigshot-caption {
	margin: 0;
    padding-top: rem(50);
    padding-bottom: rem(50);
    background-color: $colour-mid-slate;
    color: #fff;
    font-size: em(18);
    font-style: italic;
}

.bigshot-caption-content {
    padding-left: $site-gutter * 1%;
    padding-right: $site-gutter * 1%;
}

.bigshot-caption-content:before {
    height: 3px;
    width: 60px;
    content: '';
    display: block;
    margin-bottom: em(30);
    background-color: currentColor;
}


@include media(small-medium) {
    .bigshot {
        @include clearfix;
        margin-top: em(100);
        margin-bottom: em(100);
    }

    .bigshot-image {
        width: 65%;
        float: left;
    }

    .bigshot-caption {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 35%;
        display: flex;
        align-items: center;
        padding-left: 5%;
        padding-right: 5%;
    }

    .bigshot-caption-content {
        max-width: 19em;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}
