// =====================================
// Scroll Nav
// =====================================

.scroll-nav {
    padding: 0.8em 0;
}

.scroll-nav-inner {
    @include site-outer;
    position: relative;
}

.scroll-nav-item {
    display: inline-block;
    width: 30%;
    margin-right: 5%;
    text-align: center;
}

.scroll-nav-image {
    display: inline-block;
    margin-bottom: 1em;
    max-width: 70px;
}

.scroll-nav-label {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: em(14);
}

.scroll-nav-control {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $body-background-colour;
    z-index: 10;
    width: 35px;
    height: 100%;
}

.scroll-nav-control.is-disabled {
    display: none;
}

.scroll-nav-control--prev {
    left: $site-gutter * -1%;
    border-right: 1px solid $colour-grey;
}

.scroll-nav-control--next {
    right: $site-gutter * -1% ;
    border-left: 1px solid $colour-grey;
}

@media (min-width: 40em) {
    .scroll-nav-item {
        width: 25%;
        margin-right: 5%;
    }

    .scroll-nav-image {
        max-width: 100%;
    }
}

@media (min-width: 50em) {
    .scroll-nav-item {
        width: 20%;
        margin-right: 10%;
    }
}

@media (min-width: 60em) {
    .scroll-nav-item {
        width: 15%;
    }
}

@media (min-width: em($site-outer-width)) {

    .scroll-nav-inner {
        width: 100% - (2 * $site-gutter);
    }

    .scroll-nav-item {
        width: 10%;
        margin-right: 8%;
    }

    .scroll-nav-image {
        max-width: 100%;
    }

    .scroll-nav-control--prev {
        left: 0;
    }

    .scroll-nav-control--next {
        right: 0;
    }

}
