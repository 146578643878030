// =====================================
// Function - em
// =====================================

/**
 * Convert a unitless pixel value to it's em equivelent
 */

@function em($value, $base: 16) {
    @return ($value / $base) * 1em;
}
