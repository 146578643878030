// =====================================
// CHECKBOX
// =====================================

.checkbox {
    display: block;
}

.checkbox [type="checkbox"] {
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.checkbox-faux {
    width: rem(20);
    height: rem(20);
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border: 1px solid $colour-grey;
    margin-right: 0.8em;
}

.checkbox [type="checkbox"]:checked ~ .checkbox-faux {
    background: url('/dist/images/check.png') no-repeat center center;
}

.checkbox [type="checkbox"]:focus ~ .checkbox-faux,
.checkbox [type="checkbox"]:active ~ .checkbox-faux {
    border-color: transparent;
    outline: 2px solid $colour-primary;
}

.checkbox-label {
    display: inline;
    vertical-align: middle;
    font-size: em(18);
}
