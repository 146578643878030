// =====================================
// Cross Sell
// =====================================

.cross-sell {
    padding-top: em(40);
    padding-bottom: em(40);
    background-color: $colour-secondary;
}

.cross-sell:not(:first-child) {
    border-top: 1px solid $colour-grey;
}

.cross-sell-inner {
    max-width: em(1130);
	margin: 0 auto;
	width: 90%;
}

.cross-sell-description {
    margin-bottom: 1em;
    font-size: em(21);
}

@media (min-width: 70em) {

	.cross-sell {
        padding-top: em(60);
        padding-bottom: em(60);
    }

	.cross-sell-inner {
		@include clearfix;
	}

	.cross-sell-a,
	.cross-sell-b {
		float: left;
	}

	.cross-sell-a {
		width: 25%;
		margin-right: 5%;
	}

	.cross-sell-b {
		width: 70%;
	}

}
