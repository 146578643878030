// =====================================
// Function - rem
// =====================================

/**
 * Convert a unitless pixel value to it's rem equivelent
 */

@function rem($value, $base: 16) {
    @return ($value/$base) * 1rem;
}
