// =====================================
// Gallery
// =====================================

.gallery {
    margin-bottom: em(50);
    background-color: $colour-slate;
}

.gallery-item {
    margin-bottom: em(50);
    width: 100%;
}

.gallery-item:last-child {
    margin-bottom: 0;
}

.gallery-image {
    width: 100%;
}

.gallery-controls {
    @include clearfix;
    display: none;
}

.gallery-control {
    background-color: #fff;
    color: $colour-primary;
    border: 0;
    border-radius: 0;
    float: left;
    width: em(60);
    height: em(60);
    padding: 0;
}

.gallery-control:hover,
.gallery-control:focus {
    background-color: $colour-primary;
    color: #fff;
    outline: 0;
}

.gallery-control-icon {
    fill: currentColor;
    width: 13px;
    height: 20px;
}

.gallery-caption {
	margin: 0;
    color: #fff;
    font-size: em(18);
    font-style: italic;
    background-color: $colour-slate;
}

.gallery-caption-inner {
    padding-top: rem(50);
    padding-bottom: rem(20);
    padding-left: $site-gutter * 1%;
    padding-right: $site-gutter * 1%;
}

.gallery-caption-inner:before {
    content: '';
    display: block;
    height: 3px;
    width:60px;
    margin-bottom: em(30);
    background-color: currentColor;
}

.gallery.is-enhanced {

    .gallery-item {
        margin-bottom: 0;
    }

    .flickity-page-dots {
        position: static;
        padding-top: em(30);
        padding-bottom: em(30);
        background-color: $colour-slate;
    }

    .flickity-page-dots .dot {
        background-color: transparent;
        border: 1px solid #fff;
        opacity: 1;
    }

    .flickity-page-dots .dot:hover,
    .flickity-page-dots .dot.is-selected {
        background-color: #fff;
    }
}

@include media(medium) {
    .gallery {
        background: inherit;
    }

    .gallery-item {
            @include clearfix;
    }

    .gallery-caption {
        @include clearfix;
        background-color: transparent;
        color: $body-colour;
    }

    .gallery-caption-inner {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        float: right;
        max-width: 12em;
    }

    .gallery.is-enhanced {
        .gallery-controls {
            display: block;
            background-color: $body-background-colour;
        }

        .flickity-page-dots {
            display: none;
        }
    }
}
