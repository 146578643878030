.l-grid {
    @include site-outer;
    @extend .l-base;
}

@include media(small-medium) {
    .l-grid {
        @include clearfix;
        display: flex;
        flex-wrap: wrap;
    }

    .l-grid-item {
        width: 47.25%;
        float: left;
        margin-right: 5%;
    }

    .l-grid-item:nth-child(2n) {
		margin-right: 0;
    }
}

@include media(medium) {
    .l-grid-item {
        width: 30%;
        float: left;
    }

    .l-grid-item,
    .l-grid-item:nth-child(2n) {
    	margin-right: 5%;
    }

    .l-grid-item:nth-child(3n) {
		margin-right: 0;
    }
}
